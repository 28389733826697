import React from 'react'
import * as Yup from 'yup'
import { format } from 'date-fns'

import { FormGroup, Paragraph, P } from '@allied-solutions/affinity'

import { FormLayout, Hint, Notice, YesNo } from '../../../components'

const initialValues = {
  EffectiveDate: '',
  LienholderRadio: '',
}

const validationSchema = {
  EffectiveDate: Yup.date()
    // .default(() => new Date())
    .required('Please enter a valid Policy Effective Date.')
    .typeError('Please enter a valid Policy Effective Date.')
    .min('1900-01-01', 'Please enter a valid Policy Effective Date.')
    .max('2100-12-31', 'Please enter a valid Policy Effective Date.'),
  LienholderRadio: Yup.string().required(`Please select Yes or No.`),
}

const Fields = ({ values, setFieldValue, setFieldTouched }) => (
  <>
    <FormGroup
      id="EffectiveDate"
      required
      mt={9}
      css={`
        .react-datepicker__aria-live {
          display: none;
        }
      `}
    >
      <FormGroup.Label
        visible
        hint={
          <Hint
            id="Tooltip--whatsThis"
            content="The effective date is the start date of your insurance policy. The policy generally won’t cover any expenses incurred before this date."
          />
        }
      >
        Policy Effective Date
      </FormGroup.Label>

      <FormGroup.DatePicker
        placeholder="mm/dd/yyyy"
        onChange={date => {
          try {
            setFieldValue('EffectiveDate', format(date, 'MM/dd/yyyy'))
            setFieldTouched('EffectiveDate', true, false)
            document
              .querySelector('[class*="withDatePickerWrapper"] input')
              .focus()
          } catch (error) {
            // blank
          }
        }}
        value={values.EffectiveDate}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        minDate={new Date(1900, 0, 1)}
        maxDate={new Date(2100, 11, 31)}
      />
      <FormGroup.Caption />
    </FormGroup>
    <FormGroup id="LienholderRadio" mt={9} required>
      <YesNo
        label="Does your policy have a lienholder listed?"
        tooltipContent="The lienholder is the company or institution who loaned you money for this purchase, usually a bank or credit union. The name can be found in your insurance policy documents."
        checked={values.LienholderRadio}
      />
    </FormGroup>
    {values.LienholderRadio === 'false' ? (
      <Notice mt={10}>
        <P mb={0}>
          When you’re finished submitting your insurance, please call your
          insurance provider to add your financial institution as the lienholder
          on your policy.
        </P>
      </Notice>
    ) : null}
  </>
)

const PolicyInfoPage = ({ location }) => {
  return (
    <FormLayout
      leftSide={{
        title: 'Now, let’s enter your policy information.',
        body: (
          <>
            <Paragraph
              typeStyle="bodyMedium"
              textAlign="left"
              color="secondary"
            >
              Take a look at your insurance policy. The effective date of the
              policy should be listed, likely near the top of the declarations
              page.
            </Paragraph>
            <Paragraph
              typeStyle="bodyMedium"
              textAlign="left"
              color="secondary"
            >
              Also, check your policy to see if the lienholder is listed. It
              should be the name of your financial institution.
            </Paragraph>
          </>
        ),
      }}
      rightSide={{
        title: 'Policy Information',
        fields: props => <Fields {...props} />,
      }}
      {...{ location, initialValues, validationSchema }}
    />
  )
}

export default PolicyInfoPage
export { initialValues, validationSchema, Fields }
